import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <p>{`Os formatos de textos são dispostos em dois grupos, `}<strong parentName="p">{`títulos`}</strong>{` e `}<strong parentName="p">{`corpo`}</strong>{`. As classes podem ser adicionadas em qualquer elemento, ou seja, a classe `}<strong parentName="p">{`h1`}</strong>{` pode ser adicionada
em uma `}<em parentName="p">{`div`}</em>{` ou mesmo em um elemento `}<em parentName="p">{`h3`}</em>{`, por exemplo. Cada classe remete a uma formatação de tamanho e incluem tamanhos responsivos.`}</p>
    <h2 {...{
      "id": "headings"
    }}>{`Headings`}</h2>
    <br />
    <Playground __position={1} __code={'<div className=\"tw-flex tw-flex-col\">\n  <h1 className=\"tw-h1\">\n    isso é um texto <span className=\"tw-text-accent\">h1</span>\n  </h1>\n  <h2 className=\"tw-h2\">\n    isso é um texto <span className=\"tw-text-accent\">h2</span>\n  </h2>\n  <h3 className=\"tw-h3\">\n    isso é um texto <span className=\"tw-text-accent\">h3</span>\n  </h3>\n  <h4 className=\"tw-h4\">\n    isso é um texto <span className=\"tw-text-accent\">h4</span>\n  </h4>\n  <h5 className=\"tw-h5\">\n    isso é um texto <span className=\"tw-text-accent\">h5</span>\n  </h5>\n  <h6 className=\"tw-h6\">\n    isso é um texto <span className=\"tw-text-accent\">h6</span>\n  </h6>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div className="tw-flex tw-flex-col">
    <h1 className="tw-h1">
      isso é um texto <span className="tw-text-accent">h1</span>
    </h1>
    <h2 className="tw-h2">
      isso é um texto <span className="tw-text-accent">h2</span>
    </h2>
    <h3 className="tw-h3">
      isso é um texto <span className="tw-text-accent">h3</span>
    </h3>
    <h4 className="tw-h4">
      isso é um texto <span className="tw-text-accent">h4</span>
    </h4>
    <h5 className="tw-h5">
      isso é um texto <span className="tw-text-accent">h5</span>
    </h5>
    <h6 className="tw-h6">
      isso é um texto <span className="tw-text-accent">h6</span>
    </h6>
  </div>
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "body-text"
    }}>{`Body Text`}</h2>
    <br />
    <Playground __position={4} __code={'<div className=\"tw-flex tw-flex-col\">\n  <p className=\"tw-body1\">\n    isso é um texto <span className=\"tw-text-accent\">body1</span>\n  </p>\n  <p className=\"tw-body2\">\n    isso é um texto <span className=\"tw-text-accent\">body2</span>\n  </p>\n  <p className=\"tw-body3\">\n    isso é um texto <span className=\"tw-text-accent\">body3</span>\n  </p>\n  <p className=\"tw-body4\">\n    isso é um texto <span className=\"tw-text-accent\">body4</span>\n  </p>\n  <p className=\"tw-body5\">\n    isso é um texto <span className=\"tw-text-accent\">body5</span>\n  </p>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div className="tw-flex tw-flex-col">
    <p className="tw-body1">
      isso é um texto <span className="tw-text-accent">body1</span>
    </p>
    <p className="tw-body2">
      isso é um texto <span className="tw-text-accent">body2</span>
    </p>
    <p className="tw-body3">
      isso é um texto <span className="tw-text-accent">body3</span>
    </p>
    <p className="tw-body4">
      isso é um texto <span className="tw-text-accent">body4</span>
    </p>
    <p className="tw-body5">
      isso é um texto <span className="tw-text-accent">body5</span>
    </p>
  </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      